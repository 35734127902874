import { computed, onMounted } from 'vue';
import { useTheme, useRtl, useLocale } from 'vuetify';
import themeConfig from "@themeConfig";
import { usePage } from '@inertiajs/vue3'
import { usePreferredColorScheme } from '@vueuse/core'

export function useThemeSetting() {
    const user = computed(() => usePage().props.auth.user)
    const theme = useTheme();
    const themes = ['dark', 'light'];
    const preferredColor = usePreferredColorScheme()

    const setitemLocal = (name, value) => {

        if (!import.meta.env.SSR) {
            return localStorage.setItem(name, value);
        }
    }
    const getitemLocal = (name) => {


        if (!import.meta.env.SSR) {
            return localStorage.getItem(name);
        }
    }
    const toggleThemeClass = () => {

        if (!import.meta.env.SSR) {
            if (getitemLocal('theme') === 'dark' || (user && user.theme_mode == 'dark')) {
                document.documentElement.classList.add('dark')
            } else {
                document.documentElement.classList.remove('dark')
            }
        }
    }
    onMounted(() => {
        if (!import.meta.env.SSR) {
            let finalTheme = themes.includes(preferredColor.value) ? preferredColor.value : 'light';
            if (getitemLocal('theme') != undefined && (getitemLocal('theme') == 'dark' || getitemLocal('theme') == 'light')) {
                finalTheme = getitemLocal('theme');
            } else {
                setitemLocal('theme', finalTheme);
            }
            theme.global.name.value = finalTheme;
            toggleThemeClass()
            // if (getitemLocal('horizontal') != undefined && getitemLocal('horizontal') != null) {

            //     themeConfig.value.menu.horizontal = getitemLocal('horizontal') == 'true' ? true : false;


            // } else {
            //     setitemLocal('horizontal', false);
            // }
            if (getitemLocal('minitheme') != undefined && getitemLocal('minitheme') != null) {
                themeConfig.value.menu.mini = getitemLocal('minitheme') == 'true' ? true : false;

            } else {
                setitemLocal('minitheme', false);
            }

        }
    }

    )

    const toggleTheme = () => {

        theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark';

        if (!import.meta.env.SSR) {
            setitemLocal('theme', theme.global.current.value.dark ? 'dark' : 'light');

            themeConfig.value.isDark = theme.global.current.value.dark ? true : false

            toggleThemeClass();
        }
    }


    const isDark = computed(() => theme.global.current.value.dark)



    const Horizontal = computed(
        {
            get: () => themeConfig.value.menu.horizontal,
            set: (val) => {
                setitemLocal('horizontal', val);
                themeConfig.value.menu.horizontal = val
            }
        })
    const Mini = computed(
        {
            get: () => themeConfig.value.menu.mini,
            set: (val) => {
                setitemLocal('minitheme', val);
                themeConfig.value.menu.mini = val
            }
        })


    return { theme, toggleTheme, isDark, Horizontal, Mini, setitemLocal, getitemLocal, toggleThemeClass }
}
export function useTitle(props) {

    const SubTitle = computed(() => {

        if (props.isEdit) {
            return props.item.title ? props.item.title[locale] ? props.item.title[locale] : props.item.title['en'] : 'New';
        }
        if (props.type) {

            return `New ${props.type}`;
        }
        return `New `;
    })




    return { SubTitle }
}




